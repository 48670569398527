<template>
    <div>
        <ServicesHero />
        <ServicesList />
        <ContactUs />
    </div>
</template>

<script setup>

import { useHead } from '@unhead/vue'
import ServicesHero from '../components/services/ServicesHero.vue';
import ServicesList from '../components/services/ServicesList.vue';
import ContactUs from '../components/home/ContactUs.vue';

useHead({
    title: 'Our Services | BeSecure Security',
    meta: [
        { name: 'description', content: 'Discover the wide array of security services offered by BeSecure Security. We leverage the latest technology and expert oversight to customize each solution to address your unique security requirements.' },
        { name: 'keywords', content: 'security services Mauritius, advanced technology, security solutions, BeSecure Security' },
        { property: 'og:title', content: 'Our Services | BeSecure Security' },
        { property: 'og:description', content: 'Discover the wide array of security services offered by BeSecure Security. We leverage the latest technology and expert oversight to customize each solution to address your unique security requirements.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.besecure.mu/services' },
        { property: 'og:image', content: 'https://www.besecure.mu/path-to-services-image.jpg' }, // Replace with actual image URL
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:site_name', content: 'BeSecure Security' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Our Services | BeSecure Security' },
        { name: 'twitter:description', content: 'Discover the wide array of security services offered by BeSecure Security. We leverage the latest technology and expert oversight to customize each solution to address your unique security requirements.' },
        { name: 'twitter:image', content: 'https://www.besecure.mu/path-to-services-image.jpg' } // Replace with actual image URL
    ],
    link: [
        { rel: 'canonical', href: 'https://www.besecure.mu/services' }
    ]
});

</script>

<style>
/* Add your styles here */
</style>
