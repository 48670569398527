<template>
    <div>
        <HomeHero />
        <WhoWeAre />
        <Services />
        <ContactUs />
    </div>
</template>

<script setup>
import { useHead } from '@unhead/vue'
import HomeHero from '../components/home/HomeHero.vue'
import WhoWeAre from '../components/home/WhoWeAre.vue'
import Services from '../components/home/Services.vue'
import ContactUs from '../components/home/ContactUs.vue'

useHead({
    title: 'BeSecure Security | Vigilance Redefined',
    meta: [
        { name: 'description', content: 'BeSecure Security offers tailored security solutions in Mauritius. We blend advanced technology with expert insight to protect what\'s most important to you. Trust us to elevate your security, ensuring peace and safety.' },
        { name: 'keywords', content: 'security solutions, Mauritius, advanced technology, expert insight, access control, mobile patrol, rapid response, manned guarding, CCTV installation, monitoring services, BeSecure Security' },
        { property: 'og:title', content: 'BeSecure Security | Vigilance Redefined' },
        { property: 'og:description', content: 'BeSecure Security offers tailored security solutions in Mauritius. We blend advanced technology with expert insight to protect what\'s most important to you. Trust us to elevate your security, ensuring peace and safety.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.besecure.mu' },
        { property: 'og:image', content: 'https://www.besecure.mu/path-to-image.jpg' }, // Replace with actual image URL
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:site_name', content: 'BeSecure Security' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'BeSecure Security | Vigilance Redefined' },
        { name: 'twitter:description', content: 'BeSecure Security offers tailored security solutions in Mauritius. We blend advanced technology with expert insight to protect what\'s most important to you. Trust us to elevate your security, ensuring peace and safety.' },
        { name: 'twitter:image', content: 'https://www.besecure.mu/path-to-image.jpg' } // Replace with actual image URL
    ],
    link: [
        { rel: 'canonical', href: 'https://www.besecure.mu' }
    ]
})
</script>

<style>
/* Add your styles here */
</style>
