<template>
    <section id="cta">
        <div class="cta-content">
            <h1>Your Safety, Our Priority</h1>
            <p>Our dedication to excellence, coupled with a deep understanding of Mauritian security trends and
                challenges, positions us as your premier choice for reliable, innovative, and effective security
                solutions.</p>
            <div class="buttons">
                <router-link to="/services">
                    <button id="orange-btn">Our Services</button>
                </router-link>
                <router-link to="/contact">
                    <button id="black-btn">Learn More <img src="../../assets/media/arrow-icon-2.svg" alt=""></button>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    #cta{
        padding: 4rem 0;
    }

    .cta-content{
        text-align: center;
        max-width: 950px;
        margin: auto;
    }

    .cta-content h1{
        font-size: 2.5rem;
        color: var(--bs-grey);
        margin-bottom: 1.5rem;
    }

    .buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin: 2.5rem 0 0 0;
    }

    #black-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        font-weight: 500;
    }

        @media (max-width: 1200px) {
            #cta {
                padding: 3rem 1rem;
            }
    
            .cta-content {
                padding: 0 1rem;
            }
    
            .cta-content h1 {
                font-size: 2rem;
            }
    
            .buttons {
                gap: 20px;
            }
        }
    
        @media (max-width: 900px) {
            #cta {
                padding: 2.5rem 1rem;
            }
    
            .cta-content {
                padding: 0 1rem;
            }
    
            .cta-content h1 {
                font-size: 1.8rem;
            }
    
            .buttons {
                flex-direction: column;
                gap: 15px;
            }
        }
    
        @media (max-width: 600px) {
            #cta {
                padding: 2rem 1rem;
            }
    
            .cta-content {
                padding: 0 1rem;
            }
    
            .cta-content h1 {
                font-size: 1.5rem;
            }
    
            .buttons {
                flex-direction: column;
                gap: 10px;
            }
        }
</style>