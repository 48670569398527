<template>
    <div>
        <about-hero />
        <about-be-secure />
        <AboutCTA />
        <AboutValues />
        <AboutClients />
        <ContactUs />
    </div>
</template>

<script setup>

import { useHead } from '@unhead/vue'
import AboutBeSecure from '../components/about/AboutBeSecure.vue';
import AboutCTA from '../components/about/AboutCTA.vue';
import AboutHero from '../components/about/AboutHero.vue';
import AboutValues from '../components/about/AboutValues.vue';
import AboutClients from '../components/about/AboutClients.vue';
import ContactUs from '../components/home/ContactUs.vue';

useHead({
    title: 'About Us | BeSecure Security',
    meta: [
        { name: 'description', content: 'Learn more about BeSecure Security. We specialize in delivering bespoke security solutions, blending advanced technology with strategic insight.' },
        { name: 'keywords', content: 'security solutions, Mauritius, advanced technology, strategic insight, BeSecure Security' },
        { property: 'og:title', content: 'About Us | BeSecure Security' },
        { property: 'og:description', content: 'Learn more about BeSecure Security. We specialize in delivering bespoke security solutions, blending advanced technology with strategic insight.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.besecure.mu/about' },
        { property: 'og:image', content: 'https://www.besecure.mu/path-to-about-image.jpg' }, // Replace with actual image URL
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:site_name', content: 'BeSecure Security' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'About Us | BeSecure Security' },
        { name: 'twitter:description', content: 'Learn more about BeSecure Security. We specialize in delivering bespoke security solutions, blending advanced technology with strategic insight.' },
        { name: 'twitter:image', content: 'https://www.besecure.mu/path-to-about-image.jpg' } // Replace with actual image URL
    ],
    link: [
        { rel: 'canonical', href: 'https://www.besecure.mu/about' }
    ]
})
</script>

<style>
/* Add your styles here */
</style>
