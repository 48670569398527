<template>
    <section id="who-we-are">
        <div class="wwa-content">
            <div class="wwa-head">
                <h1>Who We Are</h1>
                <p>Driven by the vision of an experienced Senior Security Operations Manager, our foundation is built on
                    unparalleled expertise and a commitment to excellence.</p>
            </div>
            <div class="parent">
                <div class="left">
                    <img src="../../assets/media/besecure-about-1.png" alt="">
                </div>
                <div class="right">
                    <h1>Your Trusted Security Partner</h1>
                    <p>At the core of Be Secure Security lies a team of elite professionals, each bringing a wealth of
                        experience and specialized expertise in security management.
                        <br><br>
                        This collective proficiency enables us to deliver a full spectrum of services, from strategic
                        planning and system analysis to bespoke design and meticulous execution.
                        <br><br>
                        Our focus is unwavering: to safeguard the interests and well-being of our clients with unmatched
                        diligence and precision.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
#who-we-are {
    padding: 5rem 0;
}

.wwa-content {
    max-width: 1100px;
    margin: auto;
}

.wwa-head {
    text-align: center;
    color: var(--bs-grey);
}

.wwa-head h1 {
    font-size: 2.5rem;
}

.wwa-head p {
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 70px;
}

.left,
.right {
    flex: 1;
}

.left img {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.right {
    text-align: left;
}

.right h1 {
    color: var(--bs-grey);
    font-size: 2.5rem;
    width: 80%;
}

.right p {
    color: var(--bs-grey);
    margin: 1rem 0;
}

.check {
    display: flex;
    color: var(--bs-grey);
}

.check img {
    margin-bottom: 45px;
    margin-right: 1rem;
}

@media (max-width: 1200px) {
    #who-we-are {
        padding: 4rem 1rem;
    }

    .wwa-head p {
        width: 80%;
    }

    .parent {
        gap: 50px;
    }

    .right h1 {
        font-size: 2rem;
        width: 90%;
    }

    .left img {
        height: 400px;
    }
}

@media (max-width: 900px) {
    #who-we-are {
        padding: 3rem 1rem;
    }

    .wwa-head p {
        width: 90%;
    }

    .parent {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .right h1 {
        font-size: 1.8rem;
        width: 100%;
    }

    .left img {
        height: 300px;
    }
}

@media (max-width: 600px) {
    #who-we-are {
        padding: 4rem 2rem;
    }

    .wwa-head p {
        width: 100%;
    }

    .parent {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .right {
        text-align: center;
    }

    .right h1 {
        font-size: 1.5rem;
        width: 100%;
    }

    .right p {
        margin: 1rem 0;
    }

    .left img {
        height: 250px;
    }
}
</style>