<template>
    <section id="header">
        <div class="header-content">
            <div class="logo">
                <router-link to="/">
                    <img src="../assets/media/besecure-logo.svg" alt="">
                </router-link>
            </div>
            <div class="menu">
                <ul>
                    <router-link to="/">
                        <li>Home</li>
                    </router-link>
                    <router-link to="/about">
                        <li>About</li>
                    </router-link>
                    <router-link to="/services">
                        <li>Services</li>
                    </router-link>
                    <router-link to="/contact">
                        <li>Contact</li>
                    </router-link>
                </ul>
            </div>
            <div class="contact">
                <router-link to="/contact"><button id="orange-btn">Get A Quote</button></router-link>
                <div class="contact-details">
                    <img src="../assets/media/besecure-phone-icon.svg" alt="">
                    <div class="contact-details-text">
                        <h2>+230 5818 8684</h2>
                        <p>admin@besecure.mu</p>
                    </div>
                </div>
            </div>
            <button class="hamburger" @click="toggleMenu">☰</button>
        </div>
        <div class="hLine"></div>

        <div class="overlay" v-if="isMenuOpen" @click="toggleMenu"></div>
        <div class="mobile-menu" :class="{ open: isMenuOpen }">
            <button class="close-btn" @click="toggleMenu">✖</button>
            <ul>
                <router-link to="/" @click.native="toggleMenu">
                    <li>Home</li>
                </router-link>
                <router-link to="/about" @click.native="toggleMenu">
                    <li>About</li>
                </router-link>
                <router-link to="/services" @click.native="toggleMenu">
                    <li>Services</li>
                </router-link>
                <router-link to="/contact" @click.native="toggleMenu">
                    <li>Contact</li>
                </router-link>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }
}
</script>

<style scoped>
#header {
    background-color: transparent;
    padding: 2rem;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: auto;
    color: #fff;
}

.menu ul {
    display: flex;
    gap: 20px;
}

.contact {
    display: flex;
    align-items: center;
}

.contact-details {
    display: flex;
    align-items: center;
}

.contact-details img {
    height: 42px;
    margin: 0 1rem;
}

.contact-details-text h2 {
    font-size: 1rem;
}

.contact-details-text p {
    font-size: 13px;
}

.hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    color: #000;
    z-index: 10;
    padding: 1rem;
    /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); */
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.mobile-menu.open {
    transform: translateX(0);
}

.mobile-menu ul {
    list-style: none;
    padding: 0;
}

.mobile-menu ul li {
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
}

.mobile-menu .close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

@media (max-width: 1200px) {
    .header-content {
        padding: 0 2rem;
    }
}

@media (max-width: 900px) {
    .menu {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .contact-details img {
        height: 36px;
    }
}

@media (max-width: 600px) {
    .header-content {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 1rem;
    }

    .contact {
        display: none;
    }

    .contact-details {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-details img {
        height: 32px;
        margin: 0 0 1rem 0;
    }
}
</style>
