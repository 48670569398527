<template>
    <div>
        <ContactHero />
        <ContactForm />
    </div>
</template>

<script setup>

import { useHead } from '@unhead/vue'
import ContactHero from '../components/contact/ContactHero.vue';
import ContactForm from '../components/contact/ContactForm.vue';

useHead({
    title: 'Contact Us | BeSecure Security',
    meta: [
        { name: 'description', content: 'Get in touch with BeSecure Security for all your security needs. Contact us for bespoke security solutions, advanced technology, and expert oversight.' },
        { name: 'keywords', content: 'contact BeSecure Security, security services contact, Mauritius security solutions, BeSecure Security' },
        { property: 'og:title', content: 'Contact Us | BeSecure Security' },
        { property: 'og:description', content: 'Get in touch with BeSecure Security for all your security needs. Contact us for bespoke security solutions, advanced technology, and expert oversight.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.besecure.mu/contact' },
        { property: 'og:image', content: 'https://www.besecure.mu/path-to-contact-image.jpg' }, // Replace with actual image URL
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:site_name', content: 'BeSecure Security' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Contact Us | BeSecure Security' },
        { name: 'twitter:description', content: 'Get in touch with BeSecure Security for all your security needs. Contact us for bespoke security solutions, advanced technology, and expert oversight.' },
        { name: 'twitter:image', content: 'https://www.besecure.mu/path-to-contact-image.jpg' } // Replace with actual image URL
    ],
    link: [
        { rel: 'canonical', href: 'https://www.besecure.mu/contact' }
    ]
});

</script>

<style>
/* Add your styles here */
</style>
