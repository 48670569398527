<template>
    <section id="contact">
        <div class="contact-content">
            <h1>Contact Us</h1>
            <div class="contact-form">
                <div class="left">
                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <input type="text" id="fullName" v-model="form.fullName" placeholder="Full Name" required />
                        </div>
                        <div class="form-group">
                            <input type="email" id="email" v-model="form.email" placeholder="Email" required />
                        </div>
                        <div class="form-group">
                            <textarea id="message" v-model="form.message" placeholder="Message" required></textarea>
                        </div>
                        <button type="submit">Contact Us</button>
                    </form>
                </div>
                <div class="right">
                    <div class="right-box">
                        <h3>Contact</h3>
                        <p>admin@besecure.mu</p>
                    </div>
                    <div class="right-box right-box-b">
                        <h3>Based In</h3>
                        <p> Rempart Road, Glen Park, Vacoas, Mauritius</p>
                    </div>
                    <!-- <img src="../../assets/media/Facebook.svg" alt="" class="fb-icon"> -->
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const form = ref({
            fullName: '',
            email: '',
            message: ''
        });

        const handleSubmit = () => {
            // Handle form submission
            console.log('Form submitted', form.value);
            // You can add your form submission logic here, such as sending the data to a server.
        };

        return {
            form,
            handleSubmit
        };
    }
};
</script>

<style scoped>
#contact {
    padding: 6rem 2rem;
    background-image: url('../../assets/media/besecure-cta-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contact-content {
    max-width: 900px;
    margin: 0 auto;
    background: rgb(31, 31, 31, 0.41);
    padding: 5rem 5rem;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form{
    display: flex;
    justify-content: space-between;
    gap: 100px;
    color: #fff;
    position: relative;
}

.left,
.right{
    flex: 1;
}

h1 {
    margin-bottom: 1rem;
    color: #fff;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--bs-grey);
}

input,
textarea {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 1rem;
    background-color: transparent;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

input::placeholder,
textarea::placeholder{
    color: #fff;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

button {
    background-color: var(--bs-orange);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

button:hover {
    margin-bottom: 3px;
    margin-top: -3px;
}

.right-box{
    margin-bottom: 2rem;
}

.right-box h3{
    margin-bottom: 1rem;
}

.fb-icon{
    position: absolute;
    right: 0;
    bottom: 0;
}

@media (max-width: 1200px) {
    #contact {
        padding: 4rem 1rem;
    }

    .contact-content {
        padding: 4rem 3rem;
        border-radius: 30px;
    }

    .contact-form {
        flex-direction: column;
        gap: 50px;
    }

    .fb-icon {
        width: 50px;
        height: 50px;
        bottom: -20px;
    }
}

@media (max-width: 900px) {
    #contact {
        padding: 3rem 1rem;
    }

    .contact-content {
        padding: 3rem 2rem;
        border-radius: 20px;
    }

    .contact-form {
        flex-direction: column;
        gap: 40px;
    }

    .fb-icon {
        width: 40px;
        height: 40px;
        bottom: -15px;
    }
}

@media (max-width: 600px) {
    #contact {
        padding: 4rem 2rem;
    }

    .contact-content {
        padding: 3rem 2rem;
        border-radius: 10px;
    }

        .contact-form {
            flex-direction: column;
            gap: 30px;
            width: 100%;
        }
    
        .left,
        form,
        .form-group {
            width: 100%;
        }
    
        input,
        textarea {
            width: calc(100% - 2rem);
            /* Adjust for padding */
        }
}
</style>

