<template>
    <section id="home-hero">
        <div class="home-hero-content">
            <h1>Vigilance Redefined: Beyond Just Security</h1>
            <p>
                Every solution at BeSecure Security is tailored, blending advanced technology with expert insight to
                protect
                what's most important to you. Trust us to elevate your security, ensuring peace and safety.
            </p>
            <div class="hero-buttons">
                <router-link to="/contact"><button id="orange-btn">Get a Quote Now</button></router-link>
                <router-link to="/about"><button id="transparent-btn">Learn More</button></router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    #home-hero{
        background-image: url('../../assets/media/besecure-home-hero.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        margin-top: -7.5rem;
    }

    .home-hero-content{
        max-width: 1100px;
        margin: auto;
        padding-top: 12rem;
        color: #fff;
    }

    .home-hero-content h1{
        font-size: 4rem;
        width: 80%;
    }

    .home-hero-content p{
        width: 55%;
        margin: 2rem 0;
    }

    #orange-btn{
        margin-right: 1rem;
    }

        @media (max-width: 1200px) {
            #home-hero {
                height: 600px;
            }
    
            .home-hero-content {
                padding-top: 10rem;
            }
    
            .home-hero-content h1 {
                font-size: 3.5rem;
                width: 90%;
            }
    
            .home-hero-content p {
                width: 65%;
            }
        }
    
        @media (max-width: 900px) {
            #home-hero {
                height: 500px;
            }
    
            .home-hero-content {
                padding-top: 8rem;
            }
    
            .home-hero-content h1 {
                font-size: 3rem;
                width: 100%;
            }
    
            .home-hero-content p {
                width: 75%;
            }
        }
    
        @media (max-width: 600px) {
            #home-hero {
                height: 80vh;
            }
    
            .home-hero-content {
                padding-top: 10rem;
                text-align: left;
                padding: 10rem 2rem 0 2rem;
            }
    
            .home-hero-content h1 {
                font-size: 2.9rem;
                width: 100%;
            }
    
            .home-hero-content p {
                width: 85%;
                margin: 1.5rem 0;
            }
    
            .hero-buttons {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
        }
</style>