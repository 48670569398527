<template>
    <section id="services">
        <div class="services-content">
            <div class="services-content-header">
                <h1>Our Suite Of Services</h1>
                <p>We provide a comprehensive range of security solutions designed with cutting-edge technology and
                    expert
                    vigilance. Discover how we tailor each service to meet your specific security needs.</p>
            </div>
            <div class="parent">
                <div class="child-a">
                    <img src="../../assets/media/camera-icon.svg" alt="">
                    <h2>Manned Guarding Security Services</h2>
                    <p>Dedicated and highly trained, our security personnel ensure your environment is safe and secure
                        at all times. With strategic oversight, we offer unwavering protection.</p>
                    <router-link to="/contact"><button><img src="../../assets/media/arrow-icon.svg"
                                alt="">&nbsp;&nbsp;Explore More</button></router-link>
                </div>
                <img src="../../assets/media/besecure-security-1.png" alt="" class="parent-img">
                <div class="child-a">
                    <img src="../../assets/media/fingerprint-icon.svg" alt="">
                    <h2>Access Control Solutions</h2>
                    <p>Secure your premises with state-of-the-art access control systems that restrict and manage entry.
                        Our solutions are designed to enhance safety and streamline access.</p>
                    <router-link to="/contact"><button><img src="../../assets/media/arrow-icon.svg"
                                alt="">&nbsp;&nbsp;Explore More</button></router-link>
                </div>
                <img src="../../assets/media/besecure-security-2.png" alt="" class="parent-img">
                <img src="../../assets/media/besecure-security-3.png" alt="" class="parent-img">
                <div class="child-a">
                    <img src="../../assets/media/shield-icon.svg" alt="">
                    <h2>CCTV Installation and Monitoring Services</h2>
                    <p>Advanced surveillance technology backed by expert monitoring ensures your property is watched
                        over 24/7. Experience peace of mind with comprehensive visual coverage.</p>
                    <router-link to="/contact"><button><img src="../../assets/media/arrow-icon.svg"
                                alt="">&nbsp;&nbsp;Explore More</button></router-link>
                </div>
                <img src="../../assets/media/besecure-security-4.png" alt="" class="parent-img">
                <div class="child-a">
                    <img src="../../assets/media/camera-icon.svg" alt="">
                    <h2>Mobile Patrol and Rapid Response Services</h2>
                    <p>Quick and responsive, our mobile patrol teams are always ready to handle any security situation.
                        With rapid deployment capabilities, we safeguard your interests around the clock.</p>
                    <router-link to="/contact"><button><img src="../../assets/media/arrow-icon.svg"
                                alt="">&nbsp;&nbsp;Explore More</button></router-link>
                </div>

            </div>
            <div class="buttons">
                <router-link to="/contact"><button id="orange-btn">Get A Quote Now</button></router-link>
                <router-link to="/about"><button id="type-b-btn">Learn More&nbsp;<img src="../../assets/media/arrow-icon-2.svg" alt=""></button></router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>

    #services{
        padding: 0 2rem 6rem 0;
    }

    .services-content{
        max-width: 1200px;
        margin: auto;
    }

    .services-content h1{
        font-size: 2.5rem;
        color: var(--bs-grey);
    }

    .services-content-header{
        text-align: center;
    }

    .services-content-header p{
        width: 65%;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .parent-img{
        height: 100%;
    }

    .child-a{
        color: var(--bs-grey);
        background-color: #fff;
        padding: 2rem;
    }

    .child-a h2{
        margin: 1.5rem 0 1.5rem 0;
    }

    .child-a p{
        font-size: 15px;
    }

    button{
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-top: 2rem;
        color: var(--bs-orange);
        font-weight: 700;
    }

    .buttons{
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: center;
        margin-top: 2rem;
    }

    #type-b-btn{
        color: var(--bs-grey);
    }

        @media (max-width: 1200px) {
            #services {
                padding: 0 1rem 4rem 1rem;
            }
    
            .services-content-header p {
                width: 75%;
            }
    
            .parent {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(4, auto);
                gap: 20px;
            }
    
            .parent-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            .child-a {
                padding: 2rem;
            }
    
            .child-a h2 {
                font-size: 2rem;
            }
    
            .child-a p {
                font-size: 14px;
            }
        }
    
        @media (max-width: 900px) {
            #services {
                padding: 0 1rem 3rem 1rem;
            }
    
            .services-content-header p {
                width: 85%;
            }
    
            .parent {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(8, auto);
                gap: 20px;
            }
    
            .parent-img {
                width: 100%;
                height: auto;
            }
    
            .child-a {
                padding: 2rem;
            }
    
            .child-a h2 {
                font-size: 1.8rem;
            }
    
            .child-a p {
                font-size: 13px;
            }
        }
    
        @media (max-width: 600px) {
            #services {
                padding: 0 2rem 2rem 2rem;
            }
    
            .services-content-header p {
                width: 100%;
            }
    
            .parent {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(8, auto);
                gap: 20px;
            }
    
            .parent-img {
                display: none;
            }
    
            .child-a {
                padding: 1rem;
            }
    
            .child-a h2 {
                font-size: 1.5rem;
            }
    
            .child-a p {
                font-size: 12px;
            }

            
    
            .buttons {
                flex-direction: column;
                gap: 1rem;
            }
    
            button {
                margin-top: 1rem;
            }
        }
</style>