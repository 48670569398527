<template>
    <section id="who-we-are">
        <div class="wwa-content">
            <div class="wwa-head">
                <h1>Our Suite Of Services</h1>
                <p>We offer a wide array of security services, leveraging the latest technology and expert oversight.
                    Explore how we customize each solution to address your unique security requirements.</p>
            </div>
            <div class="parent">
                <div class="left">
                    <img src="../../assets/media/besecure-services-1.png" alt="">
                </div>
                <div class="right">
                    <img src="../../assets/media/camera-icon.svg" alt="">
                    <h1>Manned Guarding Security Services</h1>
                    <p>Our dedicated and highly trained security personnel ensure your environment is always safe and
                        secure.
                        <br><br>
                        With strategic oversight and unwavering vigilance, we provide continuous protection, giving you
                        peace of mind and confidence in our ability to handle any situation.
                    </p>
                    <router-link to="/contact"><button id="orange-btn">Get a Quote Now</button></router-link>
                </div>
            </div>
            <div class="parent">
                <div class="right">
                    <img src="../../assets/media/fingerprint-icon.svg" alt="">
                    <h1>Access Control Solutions</h1>
                    <p>Secure your premises with our advanced access control systems, expertly designed to manage and
                        restrict entry.
                        <br><br>
                        Our cutting-edge solutions enhance safety while streamlining access, providing you with robust
                        protection and seamless operation.
                    </p>
                    <router-link to="/contact"><button id="orange-btn">Get a Quote Now</button></router-link>

                </div>
                <div class="left">
                    <img src="../../assets/media/besecure-services-2.png" alt="">
                </div>
            </div>
            <div class="parent">
                <div class="left">
                    <img src="../../assets/media/besecure-services-3.png" alt="">
                </div>
                <div class="right">
                    <img src="../../assets/media/shield-icon.svg" alt="">
                    <h1>CCTV Installation and Monitoring Services</h1>
                    <p>Advanced surveillance technology backed by expert monitoring ensures your property is watched
                        over 24/7, ensuring continuous oversight and security of your premises.
                        <br><br>
                        Our state-of-the-art systems guarantee continuous security, giving you peace of mind around the
                        clock.
                    </p>
                    <router-link to="/contact"><button id="orange-btn">Get a Quote Now</button></router-link>

                </div>
            </div>
            <div class="parent parent-reverse">
                <div class="right">
                    <img src="../../assets/media/camera-icon.svg" alt="">
                    <h1>Mobile Patrol and Rapid Response Services</h1>
                    <p>Our swift and vigilant mobile patrol teams stand ready to tackle any security situation. With
                        rapid deployment and unwavering dedication, we protect your interests around the clock, ensuring
                        your safety is always our top priority.
                    </p>
                    <router-link to="/contact"><button id="orange-btn">Get a Quote Now</button></router-link>

                </div>
                <div class="left">
                    <img src="../../assets/media/besecure-services-4.png" alt="">
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
#who-we-are {
    padding: 5rem 0;
}

.wwa-content {
    max-width: 1100px;
    margin: auto;
}

.wwa-head {
    text-align: center;
    color: var(--bs-grey);
}

.wwa-head h1 {
    font-size: 2.5rem;
}

.wwa-head p {
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 70px;
}

.left,
.right {
    flex: 1;
}

.left img {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.right {
    text-align: left;
}

.right img{
    margin-bottom: 1rem;
    width: 25px;
}

.right h1 {
    color: var(--bs-grey);
    font-size: 2.5rem;
    width: 80%;
}

.right p {
    color: var(--bs-grey);
    margin: 1rem 0;
}

.check {
    display: flex;
    color: var(--bs-grey);
}

.check img {
    margin-bottom: 45px;
    margin-right: 1rem;
}

@media (max-width: 1200px) {
    #who-we-are {
        padding: 4rem 1rem;
    }

    .wwa-head p {
        width: 80%;
    }

    .parent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
    }

    .left img.main-image,
    .right img.main-image {
        display: none;
    }

    .right,
    .left {
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .right h1,
    .left h1 {
        font-size: 2rem;
        width: 90%;
    }

    .right p,
    .left p {
        width: 100%;
    }
}

@media (max-width: 900px) {
    #who-we-are {
        padding: 3rem 1rem;
    }

    .wwa-head p {
        width: 90%;
    }

    .parent {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .left img.main-image,
    .right img.main-image {
        display: none;
    }

    .right,
    .left {
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .right h1,
    .left h1 {
        font-size: 1.8rem;
        width: 100%;
    }

    .right p,
    .left p {
        width: 100%;
    }
}

@media (max-width: 600px) {
    #who-we-are {
        padding: 4rem 2rem;
    }

    .wwa-head p {
        width: 100%;
    }

    .parent {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .left img.main-image,
    .right img.main-image {
        display: none;
    }

    .right,
    .left {
        padding: 1.5rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .right,
    .left {
        text-align: center;
    }

    .right h1,
    .left h1 {
        font-size: 1.5rem;
        width: 100%;
    }

    .right p,
    .left p {
        width: 100%;
        margin: 1rem 0;
    }

    .right img{
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 30px;
    }

    .left{
        display: none;
    }
}
</style>