<template>
    <section id="who-we-are">
        <div class="wwa-content">
            <div class="wwa-head">
                <h1>Who We Are</h1>
                <p>Founded on a bedrock of security expertise, we specialise in delivering bespoke security solutions.
                    We
                    blend advanced technology with strategic insight to protect your space.</p>
            </div>
            <div class="parent">
                <div class="left">
                    <img src="../../assets/media/besecure-values.png" alt="">
                </div>
                <div class="right">
                    <h1>Most Trusted In Our Field</h1>
                    <p>At Be Secure Security, we are dedicated to reshaping how safety is perceived and delivered,
                        ensuring we not only meet but exceed the expectations of those we serve.</p>
                    <div class="check">
                        <img src="../../assets/media/besecure-check-icon.svg" alt="">
                        <p><strong>Our mission</strong> is to safeguard our clients' peace of mind, utilizing our robust
                            infrastructure
                            and innovative solutions to deliver transformative security services.</p>
                    </div>
                    <div class="check">
                        <img src="../../assets/media/besecure-check-icon.svg" alt="">
                        <p><strong>Our Vision</strong> is to set the global standard for security services, pioneering
                            advancements that
                            redefine safety and reliability for our clients across industries.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
#who-we-are {
    padding: 5rem 0;
}

.wwa-content {
    max-width: 1100px;
    margin: auto;
}

.wwa-head {
    text-align: center;
    color: var(--bs-grey);
}

.wwa-head h1 {
    font-size: 2.5rem;
}

.wwa-head p {
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.parent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 70px;
}

.left,
.right {
    flex: 1;
}

.left img {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.right {
    text-align: left;
}

.right h1 {
    color: var(--bs-grey);
    font-size: 2.5rem;
    width: 80%;
}

.right p {
    color: var(--bs-grey);
    margin: 1rem 0;
}

.check {
    display: flex;
    color: var(--bs-grey);
}

.check img {
    margin-bottom: 45px;
    margin-right: 1rem;
}

@media (max-width: 1200px) {
    #who-we-are {
        padding: 4rem 1rem;
    }

    .wwa-head p {
        width: 80%;
    }

    .parent {
        gap: 50px;
    }

    .right h1 {
        font-size: 2rem;
        width: 90%;
    }

    .left img {
        height: 400px;
    }
}

@media (max-width: 900px) {
    #who-we-are {
        padding: 3rem 1rem;
    }

    .wwa-head p {
        width: 90%;
    }

    .parent {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .right h1 {
        font-size: 1.8rem;
        width: 100%;
    }

    .left img {
        height: 300px;
    }
}

@media (max-width: 600px) {
    #who-we-are {
        padding: 4rem 2rem;
    }

    .wwa-head p {
        width: 100%;
    }

    .parent {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .right {
        text-align: center;
    }

    .right h1 {
        font-size: 1.5rem;
        width: 100%;
    }

    .right p {
        margin: 1rem 0 3rem 0;
    }

    .left img {
        height: 250px;
    }

    .check{
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        width: 90%;
        margin: auto;
    }

    .check img{
        width: 30px;
        margin: auto;
    }
}
</style>