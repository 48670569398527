<template>
    <section id="footer">
        <div class="footer-content">
            <img src="../assets/media/besecure-logo.svg" alt="" id="logo">
            <ul class="menu">
                <router-link to="/">
                    <li>Home</li>
                </router-link>
                <router-link to="/about">
                    <li>About</li>
                </router-link>
                <router-link to="/services">
                    <li>Services</li>
                </router-link>
                <router-link to="/contact">
                    <li>Contact</li>
                </router-link>
            </ul>
            <!-- <img src="../assets/media/Facebook.svg" alt="Facebook icon" class="social"> -->
            <div class="copyright">
                <p>Copyright © 2024 | BeSecure | All Rights Reserved | Built With Passion by <a
                        href="https://themerakistudio.co"><u>The Meraki Studio</u></a></p>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>

    #footer{
        padding: 4rem 0;
    }
    .footer-content{
        max-width: 1100px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .menu{
        display: flex;
        gap: 40px;
    }

    .menu li,
    .copyright p,
    .footer-content a{
        color: #6A6A6A;
        transition: all 0.2s ease-in-out;
    }

    .footer-content a:hover,
    .menu li:hover{
        color: var(--bs-orange);
    }

@media (max-width: 1200px) {
    #footer {
        padding: 3rem 0;
    }

    .footer-content {
        padding: 0 1rem;
    }

    .menu {
        gap: 30px;
    }

    .social {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 900px) {
    #footer {
        padding: 2rem 0;
    }

    .footer-content {
        padding: 0 1rem;
    }

    .menu {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .social {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 600px) {
    #footer {
        padding: 1rem 0;
    }

    .footer-content {
        padding: 0 1rem;
    }

    .menu {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .social {
        width: 30px;
        height: 30px;
    }

    .copyright p {
        text-align: center;
        font-size: 14px;
    }
}
</style>